import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import FileUpload from 'components/FileUpload/FileUpload'


function AgregarSlide(props) {
	const { mostrar, estructura, parteId, numActual, indicaciones, agregar, cerrar } = props
	const [saveEnabled, setSaveEnabled] = useState(false)

	const [slide, setSlide] = useState([])

	useEffect(() => {
		setSlide(estructura.map((elemento, i) => ({
			idProvisorio: i,
			grupo: 'slide-' + numActual,
			nombre: elemento.nombre,
			key: elemento.tipo + '-' + numActual,
			parteId: parteId,
			tipo: elemento.tipo,
			valor: '',
			upload: {}
		})))
	}, [numActual])

	const handleChange = (valor, idProvisorio) => {
		const slideCopy = [...slide]
		const componente = slideCopy.find((elem) => elem.idProvisorio === idProvisorio)
		componente.valor = valor
		slideCopy[slideCopy.indexOf(componente)] = componente

		setSlide(slideCopy)
	}

	const updateNewFiles = (files, idProvisorio) => {
		const slideCopy = [...slide]
		const componente = slideCopy.find((elem) => elem.idProvisorio === idProvisorio)
		if (files.length > 0) {
			componente.upload = {
				key: componente.key,
				files: files
			}
		} else {
			componente.upload = {}
		}
		slideCopy[slideCopy.indexOf(componente)] = componente

		setSlide(slideCopy)
		setSaveEnabled(files.length > 0)
	}

	const getJSX = (componente, i) => {
		let jsx = <div key={`componente-no-soportado-${componente.id}`} className="noSoportado">
				<i className="fas fa-exclamation-triangle fa-2x"></i>
			</div>
		switch (componente.tipo) {
			case 'imagen':
				jsx = 
					<FileUpload
						key={`slide-${componente.id}`}
						updateFiles={(files) => updateNewFiles(files, i)}
						title="Imagen para el slide*"
						accept=".jpg,.png,.jpeg"
					/>
				break
			case 'texto-corto':
			case 'texto':
			case 'link':
				jsx = 
					<Row key={`nuevo-componente-${i}`}>
						<Col>
							<Form.Group className="mb-3" controlId={`componente-${i}`}>
								<FormLabel>{componente.nombre}</FormLabel>
								<Form.Control
									value={slide.find(elem => elem.idProvisorio === i).valor}
									type="text"
									onChange={(e) => handleChange(e.target.value, i)}
								/>
							</Form.Group>
						</Col>
					</Row>
				break
		}

		return jsx
	}

	const handleAgregar = () => {
		agregar(slide, 'grupo')
		cerrar()
	}
	
	return (
		<Modal show={mostrar} onHide={cerrar}>
			<Modal.Header closeButton>
				<Modal.Title>Agregar slide</Modal.Title>
			</Modal.Header>
			<Modal.Body className="nuevo">
				<Alert variant="info">{indicaciones}</Alert>
				{slide.map((componente, i) => getJSX(componente, i))}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={cerrar}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleAgregar} disabled={!saveEnabled}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AgregarSlide