import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import { TIPO_NOTICIA_NORMAL } from 'utils/constants'

function VerNoticia(props) {
	const { noticia, setQueMostrar } = props
	const labelsDeEtiquetas = noticia.etiquetas.length > 0 ? noticia.etiquetas.map(elem => elem.nombre).join(', ') : '-'

	const handleClose = () => {
		setQueMostrar('')
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true" responsive="lg">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Ver noticia" />
			<Offcanvas.Body id="ver-noticia">
				<h1>Campos de la noticia</h1>
				{noticia.borrador && (<Row>
					<Col sm={12} md={6}>
						<Alert variant="warning">Esta noticia está en modo borrador.</Alert>
					</Col>
				</Row>)}
				<Row>
					<Col>
						<label>Título</label>
						<div className="titulo">
							{noticia.titulo}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Etiquetas</label>
						<div className="etiquetas">
							{labelsDeEtiquetas}
						</div>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col>
						<label>Copete</label>
						<div className="copete">
							{noticia.copete}
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="mb-2">
						<label>Contenido</label>
						<div className="contenido">
							{noticia.contenido}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Link</label>
						<div className="link">
							{noticia.link ? noticia.link : '-'}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Autor</label>
						<div className="autor">
							{noticia.autor}
						</div>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col>
						<label>Fecha</label>
							<div className="fecha">
								{noticia.fecha.substr(0, 16)}
							</div>
					</Col>
				</Row>
				{noticia.tipo === TIPO_NOTICIA_NORMAL && (
					<Row>
						<Col>
							<label>Imagen</label>
							<div>
								{noticia.imagen ? (<img src={process.env.REACT_APP_API_URL + noticia.imagen.url} alt=""></img>) : '-'}
							</div>
						</Col>
					</Row>
				)}
			</Offcanvas.Body >
		<PanelFooter>
			<Button variant="outline-primary" onClick={handleClose} className="me-2">
				Ok
			</Button>
		</PanelFooter>
		</Offcanvas >
	)
}

export default VerNoticia