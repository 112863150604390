import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { PanelFooter, PanelHeader } from 'components/Panel'

import '../Seccion.css'
import RichTextEditor from 'components/RichText/RichTextEditor'
import RichTextPreview from 'components/RichText/RichTextPreview'

/* 
 * Repositorios útiles para extender la funcionalidad del Rich text editor y el preview
 * https://github.com/nikgraf/awesome-draft-js
 */

const HTML = (props) => {
	const { parte, editar } = props
	const [tabKey, setTabKey] = useState('editar')
	const [error, setError] = useState('')
	const [componente, setComponente] = useState({
		id: '-',
		tipo: 'html',
		valor: ''
	})
	const [indicaciones, setIndicaciones] = useState(null)
	const [modoEdicion, setModoEdicion] = useState(false)
	const [rawState, setRawState] = useState('')
	
	useEffect(() => {
		if (typeof parte.grupos['~'] === 'undefined' || typeof parte.grupos['~'][parte.key] === 'undefined' || parte.grupos['~'][parte.key].length !== 1 || !parte.grupos['~'][parte.key][0].id) {
			setError('Parte no inicializada correctamente en la base de datos')
		} else {
			setComponente({
				id: parte.grupos['~'][parte.key][0].id,
				tipo: 'html',
				parteId: parte.id,
				valor: parte.grupos['~'][parte.key][0].valor || ''
			})
			setIndicaciones(parte.indicaciones)
		}
	}, [parte])

	const onCloseEdicion = () => {
		setModoEdicion(false)
	}

	const handleGuardar = () => {
		const componenteCopy = {...componente}
		componenteCopy.valor = JSON.stringify(rawState)

		editar(componenteCopy, 'html')
		setModoEdicion(false)
	}

	return (
		<Container fluid style={{ padding: '0 12px' }}>
			<Row className="modificacionesParte" key={`parte-${parte.id}`}>
				<Col sm={12} md={6} lg={4} className="modificacionesParteWrapper">
					<div className="modificacionesParteHeader">
						<div className="modificacionesNombre">{parte.nombre}</div>
						<div className="modificacionesTipo">, {parte.tipo}</div>
						{!modoEdicion && <Button
							onClick={() => setModoEdicion(true)}
						>
							<i title="Modificar" className="fas fa-pencil"></i> Modificar
						</Button>}
					</div>
					<div className="modificacionesDescripcion">{parte.descripcion}</div>
					<div className="modificacionesParteHTML">
						{error !== '' ? (
							<Col className="mt-3">
								<Alert variant="danger"><i className="fas fa-exclamation-triangle"></i> {error}</Alert>
							</Col>
						) : (
							<Col sm={12} md={6} lg={4}>
								<div className="valor-actual-wrapper">
									<div className="valor-actual">{componente.valor ? (<><i className="fas fa-code"></i> Contenido...</>) : '-'}</div>
								</div>
							</Col>
						)}
					</div>
				</Col>
			</Row>
			<Offcanvas show={modoEdicion} onHide={onCloseEdicion} placement="end" scroll="true" responsive="lg">
				<PanelHeader onClick={onCloseEdicion} iconClass="fa-arrow-left" btnText="Atrás" text="Editar HTML" />
				<Offcanvas.Body>
					<Tabs
						id={`tab-${parte.id}`}
						activeKey={tabKey}
						onSelect={(tab) => setTabKey(tab)}
						className="mb-3"
					>
						<Tab eventKey="editar" title="Editar">
							{indicaciones && <Alert variant="info" className="mt-3">{indicaciones}</Alert>}
							<RichTextEditor setRawState={setRawState} existingRawState={componente.valor} />
						</Tab>
						<Tab eventKey="previsualizar" title="Previsualizar">
							<RichTextPreview rawContent={rawState} />
						</Tab>
					</Tabs>

				</Offcanvas.Body>
				<PanelFooter>
					<Button
						onClick={onCloseEdicion}
						variant="outline-tertiary"
						className="me-2"
					>
						<i title="Cancelar" /> Cancelar
					</Button>
					<Button
						onClick={handleGuardar}
					>
						<i title="Guardar" className="fas fa-save"></i> Guardar
					</Button>
				</PanelFooter>
			</Offcanvas>
		</Container>
	)
}

export default HTML