import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

import { isEmpty } from 'utils/isEmpty'
import FileUpload from 'components/FileUpload/FileUpload'
import '../Seccion.css'

const Imagen = (props) => {
	const { parte, editar } = props
	const [error, setError] = useState('')
	const [componente, setComponente] = useState({
		id: '-',
		tipo: 'imagen',
		upload: {},
		key: '',
		valor: ''
	})
	const [indicaciones, setIndicaciones] = useState(null)
	const [modoEdicion, setModoEdicion] = useState(false)

	useEffect(() => {
		if (typeof parte.grupos['~'] === 'undefined' || typeof parte.grupos['~'][parte.key] === 'undefined' || parte.grupos['~'][parte.key].length !== 1 || !parte.grupos['~'][parte.key][0].id) {
			setError('Parte no inicializada correctamente en la base de datos')
		} else {
			setComponente({
				id: parte.grupos['~'][parte.key][0].id,
				tipo: 'imagen',
				upload: {},
				parteId: parte.id,
				key: parte.key,
				valor: parte.grupos['~'][parte.key][0].valor || ''
			})
			setIndicaciones(parte.indicaciones)
		}
	}, [parte])

	useEffect(() => {
		console.log('componente', componente)
	}, [componente])

	const updateImagen = (files) => {
		const componenteCopy = { ...componente }
		if (files.length > 0) {
			componenteCopy.upload = {
				key: componente.key,
				files: files
			}
		} else {
			componenteCopy.upload = {}
		}

		setComponente(componenteCopy)
	}

	const handleGuardar = () => {
		editar(componente, 'imagen')
		setModoEdicion(false)
	}

	return (
		componente.valor && (<Container fluid style={{ padding: '0 12px' }}>
			<Row className="modificacionesParte" key={`parte-${parte.id}`}>
				<Col sm={12} md={6} lg={4} className="modificacionesParteWrapper">
					<div className="modificacionesParteHeader">
						<div className="modificacionesNombre">{parte.nombre}</div>
						<div className="modificacionesTipo">, {parte.tipo}</div>
						{!modoEdicion && <Button
							onClick={() => setModoEdicion(true)}
						>
							<i title="Modificar" className="fas fa-pencil"></i> Modificar
						</Button>}
					</div>
					<div className="modificacionesDescripcion">{parte.descripcion}</div>
					<div className="modificacionesParteImagen">
						{error !== '' ? (
							<Col className="mt-3">
								<Alert variant="danger"><i className="fas fa-exclamation-triangle"></i> {error}</Alert>
							</Col>
						) : (
							<Col>
								{indicaciones && <Alert variant="info" className="mt-3">{indicaciones}</Alert>}
								{modoEdicion ? (
									<div className="modificar-valor-wrapper">
										<FileUpload
											updateFiles={updateImagen}
											title="Seleccionar imagen"
											accept=".jpg,.png,.jpeg"
										/>
										<div className="botones">
											<Button
												onClick={() => setModoEdicion(false)}
												variant="outline-tertiary"
												className="me-2"
											>
												<i title="Cancelar" /> Cancelar
											</Button>
											<Button
												onClick={handleGuardar}
												disabled={isEmpty(componente.upload)}
											>
												<i title="Guardar" className="fas fa-save"></i> Guardar
											</Button>
										</div>
									</div>
								) : (
									<div className="valor-actual-wrapper">
										<img src={componente.valor.startsWith('http') ? componente.valor : process.env.REACT_APP_API_URL + componente.valor} alt={componente.nombre} />
									</div>
								)}
							</Col>
						)}
					</div>
				</Col>
			</Row>
		</Container>)
	);
}

export default Imagen