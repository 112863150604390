import React from 'react';

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import MenuSeparator from './MenuSeparator';

import { removeItem } from 'utils/localstorage'
import { LOCALSTORAGE_KEY } from 'utils/constants'
import Logo from 'assets/iso-logo.png'
import './SideMenu.css'

function SideMenu(props) {
	const {location, history, setLoggedIn, menuExpanded, setMenuExpanded} = props

    const onToggle = (isExpanded) => {
        setMenuExpanded(isExpanded)
    }

	const handleLogOut = () => {
		setLoggedIn(false);
		removeItem(LOCALSTORAGE_KEY);
	}
	
	let selected = location.pathname.substring(1)
	if (selected === 'login' || selected === '') {
		selected = 'inicio'
	}

	return (
		<SideNav expanded={menuExpanded} onToggle={onToggle} id="sidenav"
			onSelect={(selected) => {
				const to = '/' + selected;
				if (location.pathname !== to) {
					history.push(to);
				}
			}}
		>
			<SideNav.Toggle />
			<div className="sidenavHeader">

			</div>
			<SideNav.Nav defaultSelected={selected}>
				<div className="sidenavLogo">
					<img src={Logo} alt="logo" />
				</div>
				<NavItem eventKey="inicio">
					<NavIcon>
						<i className="fa fa-fw fa-home" />
					</NavIcon>
					<NavText>
						Inicio
					</NavText>
				</NavItem>
				<NavItem eventKey="asociados">
					<NavIcon>
						<i className="far fa-id-card" />
					</NavIcon>
					<NavText>
						Asociados
					</NavText>
				</NavItem>
				<NavItem eventKey="noticias">
					<NavIcon>
						<i className="fa fa-newspaper" />
					</NavIcon>
					<NavText>
						Noticias
					</NavText>
				</NavItem>
				<NavItem eventKey="modificaciones">
					<NavIcon>
						<i className="fas fa-cog" />
					</NavIcon>
					<NavText>
						Modificaciones <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="modificaciones/inicio">
						<NavText title="Inicio">
							Inicio
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/nosotros">
						<NavText title="Nosotros">
							Nosotros
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/estatutos">
						<NavText title="Estatutos">
							Estatutos
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/asociados">
						<NavText title="Asociados">
							Asociados
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/comision-directiva">
						<NavText title="Comisión directiva">
							Comisión Directiva
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/noticias">
						<NavText title="Noticias">
							Noticias
						</NavText>
					</NavItem>
					<NavItem eventKey="modificaciones/contacto">
						<NavText title="Contacto">
							Contacto
						</NavText>
					</NavItem>
				</NavItem>
				<MenuSeparator />
				<NavItem eventKey="logout" onClick={handleLogOut}>
					<NavIcon>
						<i className="fa fa-fw fa-power-off" style={{ fontSize: '1.5em' }} />
					</NavIcon>
					<NavText style={{ paddingRight: 32 }} title="Cerrar">
						Cerrar
					</NavText>
				</NavItem>
			</SideNav.Nav>
		</SideNav>
	)
}

export default SideMenu