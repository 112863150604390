import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FormLabel, Offcanvas } from 'react-bootstrap'
import { PanelFooter, PanelHeader } from 'components/Panel'
import Creatable from 'react-select/creatable';
import FileUpload from 'components/FileUpload/FileUpload'
import { TIPO_NOTICIA_NORMAL } from 'utils/constants'

// https://www.npmjs.com/package/react-datepicker
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";

function EditarNoticia(props) {
	const { noticiaOriginal, etiquetasExistentes, guardar, setQueMostrar } = props
	const [etiquetas, setEtiquetas] = useState(noticiaOriginal.etiquetas.map(elem => ({label: elem.nombre, value: elem.id})))

	registerLocale('es', es)

	const [datosNoticia, setDatosNoticia] = useState({
		id: noticiaOriginal.id,
		borrador: noticiaOriginal.borrador === '1',
		tipo: noticiaOriginal.tipo,
		titulo: noticiaOriginal.titulo,
		copete: noticiaOriginal.copete,
		//contenido: noticiaOriginal.contenido,
		link: noticiaOriginal.link ? noticiaOriginal.link : '',
		fuente: noticiaOriginal.fuente ? noticiaOriginal.fuente : '',
		autor: noticiaOriginal.autor,
		fechaCreacion: new Date(noticiaOriginal.fechaCreacion),
		fecha: new Date(noticiaOriginal.fecha),
		imagen: false,
		etiquetas: noticiaOriginal.etiquetas.map(elem => ({label: elem.nombre, value: elem.id}))
	})

	const [erroresValidacion, setErroresValidacion] = useState({})

	const opciones = etiquetasExistentes.map(elem => ({label: elem.nombre, value: elem.id}))

	const handleClose = () => {
		setQueMostrar('')
		setDatosNoticia({})
	}

	const handleProcesar = (e) => {
		e.preventDefault()

		// verifica si se eliminó alguna etiqueta
		const etiquetasIds = etiquetas.map(elem => elem.value)
		const eliminarEtiquetasIds = []
		noticiaOriginal.etiquetas.forEach(etiqueta => {
			if (!etiquetasIds.includes(etiqueta.id)) {
				eliminarEtiquetasIds.push(etiqueta.id)
			}
		})

		const data = {
			...datosNoticia,
			etiquetas: etiquetas.map(elem => elem.label)
		}

		if (eliminarEtiquetasIds.length > 0) {
			data.eliminarEtiquetasIds = eliminarEtiquetasIds
		}

		if (!data.link) {
			delete data.link
		}

		if (validarDatos()) {
			guardar(data)
			handleClose()
		}
	}

	const validarDatos = () => {
		const camposRequeridos = ['titulo', 'autor']
		const nuevosErrores = {}

		camposRequeridos.forEach(campo => {
			if (datosNoticia[campo].trim() === '') {
				nuevosErrores[campo] = 'Este es un campo requerido'
			} else {
				nuevosErrores[campo] = null
			}
		})

		const errores = { ...erroresValidacion, ...nuevosErrores }

		setErroresValidacion(errores)

		return Object.keys(errores).filter(elem => errores[elem] !== null).length === 0
	}

	const handleChange = (value, key) => {
		const datosCopia = { ...datosNoticia }
		datosCopia[key] = value
		setDatosNoticia(prev => datosCopia)
	}

	const handleSeleccionImagen = (files) => {
		setDatosNoticia({ ...datosNoticia, imagen: files[0] })
	}

	// render
	return (
		<Offcanvas show={true} onHide={handleClose} placement="end" scroll="true" responsive="lg">
			<PanelHeader onClick={handleClose} iconClass="fa-arrow-left" btnText="Atrás" text="Editar noticia" />
			<Offcanvas.Body id="editar-noticia">
				<h1>Campos de la noticia</h1>
				{datosNoticia.id && (<Row>
					<Col sm={12} md={6}>
						<Form onSubmit={handleProcesar} className="ms-4">
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-borrador">
										<Form.Check
											type="switch"
											label="Publicada"
											checked={!datosNoticia.borrador}
											onChange={(e) => handleChange(!e.target.checked, 'borrador')}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-titulo">
										<FormLabel>Título</FormLabel>
										<Form.Control
											value={datosNoticia.titulo}
											onChange={(e) => handleChange(e.target.value, 'titulo')}
											isInvalid={erroresValidacion.titulo}
										/>
										<Form.Control.Feedback type="invalid">{erroresValidacion.titulo}</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-etiquetas">
										<FormLabel>Etiquetas</FormLabel>
										<Creatable
											defaultValue={datosNoticia.etiquetas}
											onChange={setEtiquetas}
											options={opciones}
											isMulti={true}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-copete">
										<Form.Label>Copete</Form.Label>
										<Form.Control
											as="textarea" rows={3}
											value={datosNoticia.copete}
											onChange={(e) => handleChange(e.target.value, 'copete')}
										/>
									</Form.Group>
								</Col>
							</Row>
							{/*<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-contenido">
										<Form.Label>Contenido</Form.Label>
										<Form.Control
											as="textarea" rows={15}
											value={datosNoticia.contenido}
											onChange={(e) => handleChange(e.target.value, 'contenido')}
										/>
									</Form.Group>
								</Col>
							</Row>*/}
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-link">
										<FormLabel>Link</FormLabel>
										<Form.Control
											value={datosNoticia.link}
											onChange={(e) => handleChange(e.target.value, 'link')}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-fuente">
										<FormLabel>Fuente</FormLabel>
										<Form.Control
											value={datosNoticia.fuente}
											onChange={(e) => handleChange(e.target.value, 'fuente')}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="noticia-autor">
										<FormLabel>Autor</FormLabel>
										<Form.Control
											value={datosNoticia.autor}
											onChange={(e) => handleChange(e.target.value, 'autor')}
											isInvalid={erroresValidacion.autor}
										/>
										<Form.Control.Feedback type="invalid">{erroresValidacion.autor}</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3 col-md-3" controlId="noticia-fecha">
										<FormLabel>Fecha</FormLabel>
										<DatePicker
											selected={datosNoticia.fecha}
											onChange={(fecha) => handleChange(fecha, 'fecha')}
											showTimeSelect
											locale='es'
											dateFormat="dd/MM/yyyy HH:mm"
										/>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Col>
					{datosNoticia.tipo === TIPO_NOTICIA_NORMAL && (
						<Col sm={12} md={6}>
							{noticiaOriginal.imagen && (
								<div className="text-center imagen-actual">
									<h4>Image seleccionada</h4>
									<img src={process.env.REACT_APP_API_URL + noticiaOriginal.imagen.url} alt="Imagen"/>
									<hr className="mb-5" />
								</div>
							)}

							<FileUpload
								title="Imagen"
								accept=".png, .jpg, .jpeg"
								buttonText="Elegir"
								buttonTextSingular="imagen"
								updateFiles={handleSeleccionImagen}
							/>
						</Col>
					)}
				</Row>)}
			</Offcanvas.Body>
			<PanelFooter>
				<Button variant="outline-tertiary" onClick={handleClose} className="me-2">
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleProcesar}>
					Guardar
				</Button>
			</PanelFooter>
		</Offcanvas>
	)
}

export default EditarNoticia