import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { toast } from 'react-toastify'
import Paginacion from 'components/Paginacion'
import api from 'api/api'

import './noticias.css'
import NuevaNoticia from './NuevaNoticia'
import { DEFAULT_FETCH_CUANTOS } from 'utils/constants'
import { debounce } from 'utils/debounce'
import VerNoticia from './VerNoticia'
import EliminarNoticia from './EliminarNoticia'
import EditarNoticia from './EditarNoticia'
import { Alert } from 'react-bootstrap'


function Noticias(props) {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(DEFAULT_FETCH_CUANTOS)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [noticias, setNoticias] = useState([])
	const [noticiaActual, setNoticiaActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')
	const [etiquetas, setEtiquetas] = useState([])

	const sinImagenUrl = 'https://via.placeholder.com/80x80?text=Sin+imagen'

	const opcionesCuantosMostrar = [10, 25, 50]

	const refreshConfig = async () => {
		try {
			const { datos } = await api.get('p/config-noticias')
			setEtiquetas(datos.etiquetas)
		} catch (error) {
			toast.error('Error ')
		}
	}

	const refreshNoticias = async () => {
		try {
			setLoading(true)
			const { datos } = await api.post('p/ver-noticias', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					titulo: [filtroBusqueda],
					etiqueta: [filtroBusqueda]
				}
			})

			setNoticias(datos.noticias)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshNoticias()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshNoticias()
		}
	}, [paginaAct])

	const handleAction = (noticia, cual) => {
		setNoticiaActual(noticia, setQueMostrar(cual))
	}

	const subirArchivo = async (archivo) => {
		// Primero sube el archivo, luego crea el socio
		const serverData = new FormData();
		serverData.append('foto', archivo)
		let multimediaId = null

		try {
			const { datos, msg } = await api.post('a/multimedia', serverData)
			multimediaId = datos.id
		} catch (error) {
			toast.error('Error ')
		}

		return multimediaId
	}

	const nuevaNoticia = async (data) => {
		const enviar = { ...data }
		if (data.imagen) {
			enviar.multimediaId = await subirArchivo(data.imagen)
		}
		delete enviar.imagen

		try {
			const { msg } = await api.post('a/noticias', enviar)
			refreshConfig()
			refreshNoticias()
			setQueMostrar('')
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const editarNoticia = async (data) => {
		const enviar = { ...data }
		if (data.imagen) {
			enviar.multimediaId = await subirArchivo(data.imagen)
		}
		delete enviar.imagen

		try {
			const { msg } = await api.put('a/noticias/' + data.id, enviar)
			refreshNoticias()
			setQueMostrar('')
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const eliminarNoticia = async (id) => {
		const { msg } = await api.delete('a/noticias/' + id)
		refreshConfig()
		refreshNoticias()
		setQueMostrar('')
		toast.success(msg)
	}

	useEffect(() => {
		if (!loading) {
			refreshConfig()
			refreshNoticias()
		}
	}, [])


	// render
	return (
		<Container fluid id="noticias" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col xs={12} className="mt-2">
					<h1>Noticias</h1>
					<hr />
				</Col>
			</Row>
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{ display: 'block' }}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group style={{ display: 'inline-block' }}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">noticias</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Button variant="primary" onClick={() => setQueMostrar('nueva')} className="mt-2"><i className="fa fa-plus"></i> Nueva</Button>
				</Col>
			</Row>
			{/* Lista noticias */}
			<Row>
				<Col sm={12} lg={8}>
					{loading ? (
						<Spinner
							animation="border"
							size="lg"
							role="status"
							aria-hidden="true"
							variant="primary"
							className="my-3"
						/>
					) : (
						noticias.length === 0 ? (
							<Alert variant="info">No se encontraron noticias</Alert>
						) : (
							noticias.map((noticia) => {
								return (
									<div key={noticia.id} className={`noticia border-primary ${noticia.borrador === '1' ? 'borrador' : ''}`}>
										<div className="img-wrapper">
											<img src={noticia.imagen ? process.env.REACT_APP_API_URL + noticia.imagen.url : sinImagenUrl} alt="imagen" />
										</div>
										<div className="data">
											<div className="titulo">{noticia.titulo}</div>
											<div className="etiquetas">{noticia.etiquetas.map(elem => elem.nombre).join(', ')}</div>
										</div>
										<div className="fecha">{noticia.fecha.substr(0, 16)}</div>
										<DropdownButton
											as={ButtonGroup}
											title={<i className="fa fa-ellipsis-v"></i>}
											drop="end"
										>
											<Dropdown.Item eventKey="1" onClick={() => handleAction(noticia, 'ver')}><i className="fa fa-eye"></i> Ver</Dropdown.Item>
											<Dropdown.Item eventKey="2" onClick={() => handleAction(noticia, 'editar')}><i className="fa fa-pencil"></i> Editar</Dropdown.Item>
											<Dropdown.Item eventKey="2" onClick={() => handleAction(noticia, 'eliminar')} variant="danger"><i className="fa fa-trash"></i> Eliminar</Dropdown.Item>
										</DropdownButton>
									</div>
								)
							})
						)
					)}
					<Col>
						<Paginacion
							numResultados={numResultados}
							pagina={paginaAct}
							setPagina={setPaginaAct}
							deACuantos={fetchDeACuantos}
						/>
					</Col>
				</Col>
				<Col sm={12} lg={4}>
					<h3>Leyenda</h3>
					<div id="noticias-leyenda">
						<span className="color borrador border-primary"></span> <span className="texto">Noticias no publicadas</span>
					</div>
				</Col>
			</Row>

			{/* Panel Nuevo */}
			{queMostrar === 'nueva' && (
				<NuevaNoticia
					etiquetasExistentes={etiquetas}
					guardar={nuevaNoticia}
					setQueMostrar={setQueMostrar}
				/>
			)}
			{/* Panel Ver */}
			{noticiaActual && queMostrar === 'ver' &&
				<VerNoticia
					noticia={noticiaActual}
					setQueMostrar={setQueMostrar}
				/>
			}
			{/* Panel Editar */}
			{noticiaActual && queMostrar === 'editar' &&
				<EditarNoticia
					noticiaOriginal={noticiaActual}
					etiquetasExistentes={etiquetas}
					setQueMostrar={setQueMostrar}
					guardar={editarNoticia}
				/>
			}
			{/* Modal Delete */}
			{
				noticiaActual && queMostrar === 'eliminar' &&
				<EliminarNoticia
					noticia={noticiaActual}
					setQueMostrar={setQueMostrar}
					eliminar={eliminarNoticia}
				/>
			}
		</Container>
	);
}

export default Noticias