import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

import '../Seccion.css'
import Slide from './Slide'
import AgregarSlide from './AgregarSlide'
import { isEmpty } from 'utils/isEmpty'
import DeleteSlide from './DeleteSlide'
import EditarSlide from './EditarSlide'

const Slider = (props) => {
	const { parte, agregar, editar, eliminar } = props
	const [error, setError] = useState('')
	const [slides, setSlides] = useState([])
	const [slideActual, setSlideActual] = useState(null)
	const [indicaciones, setIndicaciones] = useState(null)
	const [estructura, setEstructura] = useState({})
	const [maxSlides, setMaxSlides] = useState(-1)
	const [queMostrar, setQueMostrar] = useState('')

	useEffect(() => {
		let grupos = []
		for (let key in parte.grupos) {
			if (key !== '~') {
				const grupo = {
					nombre: key,
					parteId: parte.id,
					componentes: parte.grupos[key]
				}
				grupos.push(grupo)
			}
		}

		setSlides(grupos.map(
			grupo => {
				let elems = []
				for (let key in grupo.componentes) {
					const elem = grupo.componentes[key][0]
					elem.nombre = key
					elem.grupo = grupo.nombre
					elem.parteId = grupo.parteId
					elems.push(elem)
				}

				return elems
			})
		)
		setIndicaciones(parte.indicaciones)

		try {
			const extra = JSON.parse(parte.extra)
			setEstructura(extra.estructura)
			setMaxSlides(extra.maxSlides)
		} catch (e) {
			setError('Algo está mal con la estructura del slider')
		}
	}, [parte])

	const handleShowAgregar = () => {
		setQueMostrar('agregar')
	}

	const handleShowEdit = (slide) => {
		setSlideActual(slide)
		setQueMostrar('editar')
	}

	const handleShowDelete = (slide) => {
		setSlideActual(slide)
		setQueMostrar('eliminar')
	}

	const handleDelete = () => {
		eliminar(parte.id, slideActual[0].grupo, 'grupo')
	}

	return (
		<>
			<Container fluid style={{padding: '0 12px'}}>
				<Row className="modificacionesParte" key={`parte-${parte.id}`}>
					<Col sm={12} md={6} lg={4} className="modificacionesParteWrapper">
						<div className="modificacionesParteHeader">
							<div className="modificacionesNombre">{parte.nombre}</div>
							<div className="modificacionesTipo">, {parte.tipo}</div>
							<Button
								onClick={handleShowAgregar}
								disabled={slides.length >= maxSlides}
							>
								<i title="Agregar" className="fas fa-plus-circle"></i> Agregar
							</Button>
						</div>
						<div className="modificacionesDescripcion">{parte.descripcion}</div>
					</Col>
					{
						/* Agregar un slide */
						!isEmpty(estructura) && <AgregarSlide
							mostrar={queMostrar === 'agregar'}
							estructura={estructura}
							parteId={parte.id}
							numActual={slides.length > 0 ? slides[slides.length - 1][slides[slides.length - 1].length - 1].id : 1}
							indicaciones={indicaciones}
							agregar={agregar}
							cerrar={() => setQueMostrar('')}
						/>
					}
					{
						/* Editar un slide */
						slideActual && <EditarSlide
							mostrar={queMostrar === 'editar'}
							slide={slideActual}
							parteId={parte.id}
							indicaciones={indicaciones}
							editar={editar}
							cerrar={() => setQueMostrar('')}
						/>
					}
					{
						/* Eliminar un slide */
						slideActual &&
						<DeleteSlide
							slide={slideActual}
							mostrar={queMostrar === 'eliminar'}
							cerrar={() => setQueMostrar('')}
							eliminar={handleDelete}
						/>
					}
				</Row>
			</Container>
			<Row className="modificacionesParteSlider">
				{error !== '' ? (
					<Col>
						<Alert variant="danger">{error}</Alert>
					</Col>
				) : (
					slides.map((slide, i) =>
						<Slide
							key={`slide-${i}`}
							slide={slide}
							handleEdit={handleShowEdit}
							handleDelete={handleShowDelete}
						/>
					)
				)}
			</Row>
		</>
	);
}

export default Slider