import DOMPurify from 'dompurify';
import { useEffect } from 'react';
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichText.css'

const RichTextPreview = (props) => {
	const { rawContent } = props
	useEffect(() => {
		console.log('rawContent', rawContent)
	}, [rawContent])

	const createMarkup = (raw) => {
		const options = {
			inlineStyles: {
				// Override default element (`strong`).
				SUPERSCRIPT: {element: 'sup'},
				SUBSCRIPT: {element: 'sub'},
			},
		}
		const html = stateToHTML(convertFromRaw(raw), options)
		return {
			__html: DOMPurify.sanitize(html)
		}
	}

	return (
		rawContent && <div className="rich-text-preview" dangerouslySetInnerHTML={createMarkup(rawContent)}></div>
	)
}

export default RichTextPreview