import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Navigate, Switch } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { isLoggedIn } from './utils/general'
import SideMenu from 'components/SideMenu/SideMenu'

import NoMatch from './pages/NoMatch'
import Login from './pages/login/Login'
import Home from './pages/home/Home'
import {Asociados} from './pages/asociados'
import {Noticias} from './pages/noticias'
import { Inicio, Nosotros, Estatutos, ComisionDirectiva, Contacto } from './pages/modificaciones'
import { TestComponent } from 'pages/test'
import ModificacionesAsociados from 'pages/modificaciones/ModificacionesAsociados'
import ModificacionesNoticias from 'pages/modificaciones/ModificacionesNoticias'

function App() {
	// login state
	const [loggedIn, setLoggedIn] = useState(
		isLoggedIn()
	);

	const [menuExpanded, setMenuExpanded] = useState(true)
	
	if (menuExpanded) {
		document.body.classList.add('menu-expanded')
	} else {
		document.body.classList.remove('menu-expanded')
	}

	useEffect(() => {
		setLoggedIn(isLoggedIn())
	}, []);

	// render
	return (
		<>
			<ToastContainer />
			<Router basename={process.env.PUBLIC_URL}>
					<Route render={({ location, history }) => (
						<>
							<Route exact path="/login" component={props => <Login setLoggedIn={(setLoggedIn)} history={history} />} />
							{loggedIn && <SideMenu location={location} history={history} setLoggedIn={setLoggedIn} menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} />}
							<main className={menuExpanded ? 'section expanded' : 'section'}>
								<Switch>
									<Route exact path="/" render={() => {
										return (
											<Redirect to="/inicio" /> 
										)
									}}/>
									<Route exact path="/inicio">
										<Home />
									</Route>
									<Route exact path="/asociados">
										<Asociados />
									</Route>
									<Route exact path="/noticias">
										<Noticias />
									</Route>
									<Route exact path="/modificaciones/inicio">
										<Inicio />
									</Route>
									<Route exact path="/modificaciones/nosotros">
										<Nosotros />
									</Route>
									<Route exact path="/modificaciones/estatutos">
										<Estatutos />
									</Route>
									<Route exact path="/modificaciones/asociados">
										<ModificacionesAsociados />
									</Route>
									<Route exact path="/modificaciones/comision-directiva">
										<ComisionDirectiva />
									</Route>
									<Route exact path="/modificaciones/noticias">
										<ModificacionesNoticias />
									</Route>
									<Route exact path="/modificaciones/contacto">
										<Contacto />
									</Route>
									<Route exact path="/test">
										<TestComponent />
									</Route>
									{loggedIn && <Route path="*" component={props => <NoMatch />} />}
								</Switch>
							</main>
						</>
					)}
					/>
					{!loggedIn && <Redirect to="/login" />}
			</Router>
		</>
	);
}

export default App
