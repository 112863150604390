import React, { useEffect, useState } from 'react'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, convertFromRaw } from 'draft-js'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichText.css'

const RichTextEditor = (props) => {
	const { setRawState, existingRawState } = props

	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

	const handleEditorChange = (state) => {
		setEditorState(state);
	}

	useEffect(() => {
		let state = ''
		if (existingRawState === '') {
			state = EditorState.createEmpty()
		} else {
			const raw = convertFromRaw(JSON.parse(existingRawState))
			state = EditorState.createWithContent(raw)
		}

		setEditorState(state)
	}, [existingRawState])

	useEffect(() => {
		setRawState(convertToRaw(editorState.getCurrentContent()))
	}, [editorState])

	function uploadImageCallBack(file) {
		return new Promise(
			(resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.open('POST', 'https://api.imgur.com/3/upload');
				xhr.setRequestHeader('Authorization', 'Client-ID fa8e28101939397');
				const data = new FormData();
				data.append('image', file);
				xhr.send(data);
				xhr.addEventListener('load', () => {
					const response = JSON.parse(xhr.responseText);
					console.log(response)
					resolve(response);
				});
				xhr.addEventListener('error', () => {
					const error = JSON.parse(xhr.responseText);
					console.log(error)
					reject(error);
				});
			}
		);
	}

	return (
		<Editor
			editorState={editorState}
			onEditorStateChange={handleEditorChange}
			wrapperClassName="rich-text-wrapper-class"
			editorClassName="rich-text-editor-class"
			toolbarClassName="rich-text-toolbar-class"
			toolbar={{
				list: { inDropdown: true },
				textAlign: { inDropdown: true },
				link: { inDropdown: true },
				history: { inDropdown: true },
				image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
			}}
		/>
	)
}

export default RichTextEditor