export const LOCALSTORAGE_KEY = 'apap'
export const DEFAULT_FETCH_CUANTOS = 10

export const TIPO_NOTICIA_NORMAL = 'normal'

export const ETIQUETAS_SOCIOS = [
	{value: 'full-service', label: 'Full service'},
	{value: 'digital', label: 'Digital'},
	{value: 'productora', label: 'Productora'},
	{value: 'medios', label: 'Medios'},
	{value: 'branding', label: 'Branding'},
	{value: 'editorial', label: 'Editorial'},
	{value: 'agencia-eco', label: 'Agencia Eco'},
	{value: 'via-publica', label: 'Vía pública'}
]