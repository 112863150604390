import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function DeleteSlide(props) {
	const { slide, mostrar, cerrar, eliminar } = props
	
	return (
		<Modal show={mostrar} onHide={cerrar} className="deleteSlide">
			<Modal.Header closeButton>
				<Modal.Title>Eliminar slide</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<img src={process.env.REACT_APP_API_URL +  slide.find((componente) => componente.tipo === 'imagen').valor} alt="slide" />
				<Alert variant="danger">
					Está por eliminar el slide. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={cerrar}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={eliminar}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default DeleteSlide