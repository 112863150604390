import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'

import { toast } from 'react-toastify'

import api from 'api/api'
import './Asociados.css'
import { debounce } from 'utils/debounce'
import Paginacion from 'components/Paginacion'
import NuevoSocio from './NuevoSocio'
import DeleteSocio from './DeleteSocio'
import { ETIQUETAS_SOCIOS } from 'utils/constants'
import VerSocio from './VerSocio'
import EditarSocio from './EditarSocio'

function Asociados() {
	const [fetchDeACuantos, setFetchDeACuantos] = useState(10)
	const [numResultados, setNumResultados] = useState(0)
	const [paginaAct, setPaginaAct] = useState(1)
	const [loading, setLoading] = useState(false)

	const [busqueda, setBusqueda] = useState('')
	const [filtroBusqueda, setFiltroBusqueda] = useState('')
	const [socios, setSocios] = useState([])
	const [socioActual, setSocioActual] = useState()
	const [queMostrar, setQueMostrar] = useState('')

	const etiquetasSocios = ETIQUETAS_SOCIOS

	const hoy = new Date().toISOString().substring(0, 10)

	const refreshSocios = async() => {
		try {
			setLoading(true)
			const { datos } = await api.post('a/ver-socios', {
				cuantos: fetchDeACuantos,
				desde: paginaAct === 1 ? 0 : (paginaAct - 1) * fetchDeACuantos,
				filtros: {
					nombre: [filtroBusqueda]
				}
			})
			
			setSocios(datos.socios)
			setNumResultados(datos.numResultados)
		} catch (error) {
			toast.error('Error de conexión')
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleBusqueda = (value) => {
		if (value) {
			setFiltroBusqueda(value)
		} else {
			setFiltroBusqueda('')
		}
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		debouceFiltroBusqueda(busqueda)
	}, [busqueda])

	useEffect(() => {
		if (paginaAct === 1) {
			refreshSocios()
		} else {
			setPaginaAct(1)
		}
	}, [filtroBusqueda, fetchDeACuantos])

	useEffect(() => {
		if (!loading) {
			refreshSocios()
		}
	}, [paginaAct])

	const opcionesCuantosMostrar = [10, 25, 50]

	const handleAction = (socio, cual) => {
		setSocioActual(socio, setQueMostrar(cual))
	}

	const subirArchivo = async(archivo) => {
		// Primero sube el archivo, luego crea el socio
		const serverData = new FormData();
		serverData.append('foto', archivo)
		let multimediaId = null

		try {
			const { datos, msg } = await api.post('a/multimedia', serverData)
			multimediaId = datos.id
			console.log(datos)
			console.log(multimediaId)
		} catch (error) {
			toast.error('Error ')
		}

		return multimediaId
	}

	const nuevoSocio = async(data) => {
		const enviar = {...data}
		if (data.imagen) {
			enviar.multimediaId = await subirArchivo(data.imagen)
		}
		delete enviar.imagen

		try {
			const { msg } = await api.post('a/socios', enviar)
			refreshSocios()
			setQueMostrar('')
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const editarSocio = async(data) => {
		const enviar = {...data}
		if (data.imagen) {
			enviar.multimediaId = await subirArchivo(data.imagen)
		}
		delete enviar.imagen

		try {
			const { msg } = await api.put('a/socios/' + data.id, enviar)
			refreshSocios()
			setQueMostrar('')
			toast.success(msg)
		} catch (error) {
			console.error(error)
			toast.error('Error de conexión')
		}
	}

	const eliminarSocio = async(id) => {
		const { msg } = await api.delete('a/socios/' + id)
		refreshSocios()
		setQueMostrar('')
		toast.success(msg)
	}

	// render
	return (
		<Container fluid id="socios" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					<Form>
						<Row id="searchbar" className="justify-content-between align-items-center">
							<Col sm={6} md={3} style={{display: 'block'}}>
								<small>
									<span className="d-none d-sm-inline-block">Mostrar de a</span>
									<Form.Group className="mb-3" style={{display: 'inline-block'}}>
										<Form.Select size="sm" onChange={(e) => setFetchDeACuantos(e.target.value)} className="mx-1">
											{opcionesCuantosMostrar.map((o) => {
												return (
													<option value={o} key={`mostrar-${o}`}>
														{o}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<span className="ms-2">socios</span>
								</small>
							</Col>
							<Col md={5} lg={4}>
								<Form.Group controlId="txtBusqueda">
									<InputGroup>
										<Form.Control
											autoFocus
											value={busqueda}
											onChange={(e) => setBusqueda(e.target.value)}
											type="text"
											placeholder="Buscar"
											aria-label="Buscar"
										/>
										<InputGroup.Text><Form.Label className="mb-0"><i className="fa fa-search"></i></Form.Label></InputGroup.Text>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Row className="d-flex no-gutters justify-content-between align-items-end mt-2">
				<Col>
					<Button onClick={() => setQueMostrar('nuevo')} variant="primary">
						<i className="fa fa-plus"></i> Nuevo socio
					</Button>
				</Col>
				<Col className="text-end">
					<small>Total resultados: <strong>{numResultados}</strong></small>
				</Col>
			</Row>
			{/* Table */}
			<Row>
				<Col>
					<Table id="tabla-asociados" className="mt-2" bordered hover>
						<thead>
							<tr>
								<th>Id</th>
								<th>Logo</th>
								<th>Nombre</th>
								<th>Etiquetas</th>
								<th>Telefono</th>
								<th>Email</th>
								<th>Orden</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td colSpan="6">
										<Spinner
											animation="border"
											size="lg"
											role="status"
											aria-hidden="true"
											variant="primary"
											className="my-3"
										/>
									</td>
								</tr>
							) : (
								socios.map((socio, index) => {
									return (
										<tr key={socio.id} className={`${socio.habilitado === '0' ? 'inhabilitado' : ''}`}>
											<td>{socio.id}</td>
											<td style={{textAlign: 'center'}}><img src={socio.multimedia ? process.env.REACT_APP_API_URL + 'uploads/' + socio.multimedia.url : 'logo192.png'} alt={socio.nombre}/></td>
											<td>{socio.nombre}</td>
											<td>{socio.etiquetas.split(',').map(etiqueta => etiquetasSocios.find(e => e.value === etiqueta).label).join(', ')}</td>
											<td>{socio.telefono}</td>
											<td>{socio.email}</td>
											<td>{socio.orden || '-'}</td>
											<td>
												<Button
													onClick={() => handleAction(socio, 'ver')}
													className="me-2"
													variant="primary"
												>
													<i className="fa fa-eye"></i>
												</Button>
												<Button
													onClick={() => handleAction(socio, 'editar')}
													className="me-2"
													variant="secondary"
												>
													<i className="fa fa-pencil"></i>
												</Button>
												<Button
													onClick={() => handleAction(socio, 'eliminar')}
													variant="danger"
												>
													<i className="fa fa-trash"></i>
												</Button>
											</td>
										</tr>
									)
								})
							)}
							</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginacion
						numResultados={numResultados}
						pagina={paginaAct}
						setPagina={setPaginaAct}
						deACuantos={fetchDeACuantos}
					/>
				</Col>
			</Row>
			{/* Panel Nuevo */}
			{queMostrar === 'nuevo' && (
				<NuevoSocio 
					guardar={nuevoSocio}
					setQueMostrar={setQueMostrar}
				/>
			)}
			{/* Panel Ver */}
			{socioActual && queMostrar === 'ver' &&
				<VerSocio 
					socio={socioActual}
					setQueMostrar={setQueMostrar}
				/>
			}
			{/* Panel Editar */}
			{socioActual && queMostrar === 'editar' &&
				<EditarSocio 
					socioOriginal={socioActual}
					setQueMostrar={setQueMostrar}
					guardar={editarSocio}
				/>
			}
			{/* Modal Delete */}
			{
				socioActual &&
					<DeleteSocio
						socio={socioActual}
						mostrar={queMostrar === 'eliminar'}
						setQueMostrar={setQueMostrar}
						eliminarSocio={eliminarSocio}
					/>
			}
		</Container>
	)
}

export default Asociados