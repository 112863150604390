import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import HTML from './HTML/HTML'
import Imagen from './Imagen/Imagen'

import './Seccion.css'
import Slider from './Slider/Slider'
import Texto from './Texto/Texto'
import TextoCorto from './TextoCorto/TextoCorto'

const Parte = (props) => {
	const { original, agregar, editar, eliminar } = props
	const [datos, setDatos] = useState({})
	const [queMostrar, setQueMostrar] = useState('')

	useEffect(() => {
		setDatos({...original})
	}, [original])

	const getJSX = (parte) => {
		let jsx = <Alert key={`parte-no-soportada-${parte.id}`} className="mt-4" variant="danger">{'Tipo no soportado: ' + parte.tipo}</Alert>
		switch (parte.tipo) {
			case 'slider':
				jsx = <Slider 
					key={`slider-${parte.id}`}
					parte={parte}
					queMostrar={queMostrar}
					setQueMostrar={setQueMostrar}
					agregar={agregar}
					editar={editar}
					eliminar={eliminar}
				/>
				break
			case 'texto-corto':
				jsx = <TextoCorto
					key={`texto-corto-${parte.id}`}
					parte={parte}
					editar={editar}
				/>
				
				break
			case 'texto':
				jsx = <Texto
					key={`texto-${parte.id}`}
					parte={parte}
					editar={editar}
				/>
				
				break
			case 'html':
				jsx = <HTML
					key={`html-${parte.id}`}
					parte={parte}
					editar={editar}
				/>

				break
			case 'imagen':
				jsx = <Imagen
					key={`imagen-${parte.id}`}
					parte={parte}
					editar={editar}
				/>
				break
		}

		return jsx
	}

	return (
		getJSX(datos)
	)
}

export default Parte