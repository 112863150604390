import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function DeleteSocio(props) {
	const { socio, mostrar, setQueMostrar, eliminarSocio } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={mostrar} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar Socio</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar al socio <strong>{`${socio.nombre}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={() => eliminarSocio(socio.id)}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default DeleteSocio