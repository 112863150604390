import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'


function EliminarNoticia(props) {
	const { noticia, setQueMostrar, eliminar } = props

	const handleClose = () => {
		setQueMostrar('')
	}
	
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar Noticia</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="danger">
					Está a punto de eliminar la noticia <strong>{`${noticia.titulo}`}</strong>. Esta acción no se puede deshacer.
				</Alert>
				<p>¿Está seguro que desea continuar?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={() => eliminar(noticia.id)}>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EliminarNoticia