import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'

import '../Seccion.css'

const Slide = (props) => {
	const { slide, handleEdit, handleDelete } = props

	const getJSX = (componente) => {
		let jsx = <div key={`componente-no-soportado-${componente.id}`} className="noSoportado">
				<i className="fas fa-exclamation-triangle fa-2x" title={`Componente "${componente.tipo}" no soportado dentro de un slide`}></i>
			</div>
		switch (componente.tipo) {
			case 'imagen':
				jsx = 
					<div 
						className="modificacionesParteSliderImage"
						key={`slide-${componente.id}`}
					>
						<img src={process.env.REACT_APP_API_URL + componente.valor} alt={componente.nombre}/>
					</div>
				break
			case 'texto-corto':
			case 'texto':
				jsx = 
					<div 
						className="modificacionesParteSliderTextoCorto"
						key={`slide-${componente.id}`}
					>
						{componente.valor}
					</div>
				break
			case 'link':
				jsx = 
					<div 
						className="modificacionesParteSliderLink"
						key={`slide-${componente.id}`}
					>
						<a href={componente.valor} target="_blank">{componente.valor}</a>
					</div>
				break
		}

		return jsx
	}

	return (
		<Col className="slide" sm={12} md={6} lg={4}>
			<div className="slide-controls">
				<i className="fas fa-pencil" style={{color: '#004085'}} onClick={() => handleEdit(slide)}></i>
				<i className="fas fa-trash" style={{color: '#7f2e36'}} onClick={() => handleDelete(slide)}></i>
			</div>
			{slide.map(componente => {
				return getJSX(componente)
			})}
		</Col>
	);
}

export default Slide