import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import FileUpload from 'components/FileUpload/FileUpload'
import { isEmpty } from 'utils/isEmpty'


function EditarSlide(props) {
	const { mostrar, slide, parteId, indicaciones, editar, cerrar } = props
	const [saveEnabled, setSaveEnabled] = useState(false)

	const [slideEditado, setSlideEditado] = useState(slide.map(componente => {return {...componente, upload: {}}}))

	useEffect(() => {
		setSlideEditado(slide.map(componente => {return {...componente, upload: {}}}))
	}, [slide])

	useEffect(() => {
		setSaveEnabled(hayCambios())
	}, [slideEditado])

	const hayCambios = () => {
		let cambios = false
		slideEditado.forEach((componente) => {
			if (!isEmpty(componente.upload)) {
				cambios = true
			} else {
				if (componente.valor !== slide.find((elem) => elem.id === componente.id).valor) {
					cambios = true
				}
			}
		})

		return cambios
	}

	const handleChange = (valor, id) => {
		const slideCopy = [...slideEditado]
		const componente = slideCopy.find((elem) => elem.id === id)
		componente.valor = valor
		slideCopy[slideCopy.indexOf(componente)] = componente

		setSlideEditado(slideCopy)
	}

	const updateNewFiles = (files, id) => {
		const slideCopy = [...slideEditado]
		const componente = slideCopy.find((elem) => elem.id === id)
		if (files.length > 0) {
			componente.upload = {
				key: componente.nombre,
				files: files
			}
		} else {
			componente.upload = {}
		}
		slideCopy[slideCopy.indexOf(componente)] = componente

		setSlideEditado(slideCopy)
	}

	const getJSX = (componente) => {
		let jsx = <div key={`componente-no-soportado-${componente.id}`} className="noSoportado">
				<i className="fas fa-exclamation-triangle fa-2x"></i>
			</div>
		switch (componente.tipo) {
			case 'imagen':
				jsx = 
					<div key={`slide-${componente.id}`} className="editar-imagen">
						<FileUpload
							updateFiles={(files) => updateNewFiles(files, componente.id)}
							title="Imagen para el slide*"
							accept=".jpg,.png,.jpeg"
						/>
						{isEmpty(componente.upload) && <img className="imagen-existente" src={process.env.REACT_APP_API_URL + componente.valor} alt="Imagen seleccionada"/>}
					</div>
				break
			case 'texto-corto':
			case 'texto':
			case 'link':
				jsx = 
					<Row key={`nuevo-componente-${componente.id}`}>
						<Col>
							<Form.Group 
								className={`mb-3 ${(slideEditado.find(elem => elem.id === componente.id).valor !== slide.find(elem => elem.id === componente.id).valor ? 'valor-modificado': '')}`}
								controlId={`componente-${componente.id}`}
							>
								<FormLabel>{componente.nombre}</FormLabel>
								<Form.Control
									value={slideEditado.find(elem => elem.id === componente.id).valor}
									type="text"
									onChange={(e) => handleChange(e.target.value, componente.id)}
								/>
							</Form.Group>
						</Col>
					</Row>
				break
		}

		return jsx
	}

	const handleCerrar = () => {
		const slideCopy = [...slideEditado]
		const conUploads = slideCopy.filter((componente) => !isEmpty(componente.upload))
		
		conUploads.forEach((componente) => {
			slideCopy[slideCopy.indexOf(componente)] = {...componente, upload: {}}
		})

		setSlideEditado(slideCopy)

		cerrar()
	}

	const handleEditar = () => {
		console.log('editando slideEditado', slideEditado)
		editar(slideEditado.map(componente => {return {...componente, key: componente.nombre}}), 'grupo')
		//cerrar()
	}
	
	return (
		<Modal show={mostrar} onHide={handleCerrar}>
			<Modal.Header closeButton>
				<Modal.Title>Editar slide</Modal.Title>
			</Modal.Header>
			<Modal.Body className="nuevo">
				<Alert variant="info">{indicaciones}</Alert>
				{slideEditado.map((componente) => getJSX(componente))}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-tertiary" onClick={handleCerrar}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleEditar} disabled={!saveEnabled}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditarSlide